.App-header {
  top: 0;
  margin-left: 25px;
  font-size: calc(10px + 2vmin);
}

.App {
  height: 100vh;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  overflow-y: scroll;
}

.maxCheck {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.modalReactClass {
  background-color: white;
  max-width: 560px;
  /* min-height: 660px; */
  display: block;
  margin: 120px auto;
  box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
  border-radius: 11px;
}

.errorModalReactClass {
  background-color: white;
  max-width: 560px;
  display: block;
  margin: 120px auto;
  box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
  border-radius: 11px;
}

.addLocationModalReactClass {
  background-color: white;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  max-width: 560px;
  min-height: auto;
  display: block;
  margin: 120px auto;
  box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
  border-radius: 11px;
  overflow-x: auto;
}

.addAdminModalReactClass, .addBusinessAreaModal {
  background-color: white;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  max-width: 560px;
  min-height: auto;
  display: block;
  margin: 120px auto;
  box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
  border-radius: 11px;
  overflow-y: auto;
  overflow-x: auto;
}

.registerDesksModal {
  background: #11a2d6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.floorPlanModal {
  background-color: white;
  max-width: 1080px;
  display: block;
  margin: 120px auto;
  box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
  border-radius: 11px;
  /* min-height: 600px; */
  overflow-x: hidden;
  overflow-y: hidden;
}

.modalCloseButton {
  background: rgba(67, 90, 111, 0.1) !important;
  border-radius: 5px !important;
  width: 40px;
  height: 31px;
  color: #222222 !important;
}

.floorPlanModal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
  overflow-x: scroll;
  overflow-y: scroll;
}
.addLocation-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
  overflow-y: scroll;
  height: 100%;
}

.registerDesksModal-overlay {
  background-color: #11a2d6;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  margin-top: 90px;
  z-index: 999;
  overflow-y: hidden;
}

.menubar-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.confirm-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
  height: auto !important;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
  /* height: fit-content; */
}

.error-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
}

.reservedModal-overlay {
  background-color: inherit;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 100;
  border: none;
}

.menuBarModal {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 10000;
}

.btn-primary {
  border: none !important;
  border-color: none !important;
  transition: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
  transition: none !important;
}
.btn .buttonSpanText {
  text-transform: uppercase;
  font-size: 12px;
}
