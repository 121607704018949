@font-face {
  font-family: 'SimplonNorm-Bold';
  src: local('SimplonNorm-Bold'), url(./client/assets/fonts/SimplonNorm-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'SimplonNorm-Medium';
  src: local('SimplonNorm-Medium'), url(./client/assets/fonts/SimplonNorm-Medium.otf) format('opentype');
}


@font-face {
  font-family: 'SimplonNorm-Regular';
  src: local('SimplonNorm-Regular'), url(./client/assets/fonts/SimplonNorm-Regular.otf) format('opentype');
}

